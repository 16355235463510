var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.closeState),callback:function ($$v) {_vm.closeState=$$v},expression:"closeState"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" LỊCH SỬ GIAO DỊCH: "+_vm._s(_vm.account)+" "),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"dark":""},on:{"click":function($event){return _vm.doClose()}}},[_vm._v(" Đóng ")])],1),_c('v-card-text',[_c('v-text-field',{staticClass:"mx-2",attrs:{"label":"Tìm kiếm"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-responsive',[_c('Loader',{attrs:{"status":_vm.loadingState}}),_c('v-data-table',{attrs:{"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loadingState,"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"loading-text":"Đang tải dữ liệu...","footer-props":{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]},"items-per-page":20},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.PaymentType",fn:function(ref){
var item = ref.item;
return [(item.Balance && !item.BankTransfer)?_c('div',{staticClass:"text-body2 green--text text-center"},[_vm._v(" Ví TO ")]):_vm._e(),(item.BankTransfer)?_c('div',{staticClass:"text-body2 blue--text text-center"},[_vm._v(" Chuyển khoản ")]):_vm._e()]}},{key:"item.Confirmed",fn:function(ref){
var item = ref.item;
return [(item['Confirmed'])?[_c('v-btn',{attrs:{"color":"red","dark":"","small":""}},[_vm._v(" Đã đối soát ")])]:(!item['Confirmed'] && _vm.doConfirm !== undefined && (item.Balance || item['BankTransfer']))?[((item.Balance && item.Balance.Plus) || item['BankTransfer'])?_c('v-btn',{attrs:{"color":"blue","dark":!_vm.confirmState,"small":"","disabled":_vm.confirmState,"loading":_vm.confirmState},on:{"click":function($event){return _vm.doConfirm(item._id)}}},[_vm._v(" Xác nhận ")]):_vm._e()]:_vm._e(),(!item['Confirmed'] && _vm.doConfirm === undefined && item['BankTransfer'])?[_c('v-btn',{attrs:{"color":"blue","dark":"","small":""}},[_vm._v(" Chưa đối soát ")])]:_vm._e()]}},{key:"item.Order",fn:function(ref){
var item = ref.item;
return [(item.Order)?[_vm._v(" "+_vm._s(item.Order.Order)+" / "+_vm._s(item.Order.Product)+" ")]:[_c('span',{staticClass:"text-body2 red--text"},[_vm._v("Trống")])]]}},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [(item.Amount)?_c('div',{staticClass:"text-body2 green--text text-center"},[_vm._v(" "+_vm._s(_vm.DoiTien(item.Amount))+" ")]):_vm._e()]}},{key:"item.Balance",fn:function(ref){
var item = ref.item;
return [(item.Balance)?[(item.Balance['Plus'])?_c('div',{staticClass:"text-body2 green--text text-center"},[_vm._v("+"+_vm._s(_vm.DoiTien(item.Balance.Plus)))]):_vm._e(),(item.Balance['Minus'])?_c('div',{staticClass:"text-body2 red--text text-center"},[_vm._v("-"+_vm._s(_vm.DoiTien(item.Balance.Minus)))]):_vm._e()]:_vm._e()]}},{key:"item.Balance.Before",fn:function(ref){
var item = ref.item;
return [(item.Balance)?[_c('div',{staticClass:"text-body2 blue--text text-center"},[_vm._v(_vm._s(_vm.DoiTien(item.Balance.Before)))])]:_vm._e()]}},{key:"item.Balance.After",fn:function(ref){
var item = ref.item;
return [(item.Balance)?[(item.Balance['Plus'])?_c('div',{staticClass:"text-body2 green--text text-center"},[_vm._v(_vm._s(_vm.DoiTien(item.Balance.After)))]):_vm._e(),(item.Balance['Minus'])?_c('div',{staticClass:"text-body2 red--text text-center"},[_vm._v(_vm._s(_vm.DoiTien(item.Balance.After)))]):_vm._e()]:_vm._e()]}},{key:"item.Notes",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{attrs:{"value":item.Notes,"label":"Ghi chú (Khách hàng)","readonly":"","rows":"1"}})]}},{key:"item.Logs",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{attrs:{"value":item.Logs,"label":"Ghi chú (Nội bộ)","readonly":"","rows":"1"}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.TGian(item.createdAt))+" ")]}},{key:"item.Image",fn:function(ref){
var item = ref.item;
return [(item.Image)?[_c('v-img',{attrs:{"src":((_vm.Global.staticURL) + "/" + (item.Image)),"lazy-src":((_vm.Global.staticURL) + "/" + (item.Image)),"aspect-ratio":"1","contain":"","max-width":"250px","max-height":"250px","lazy-load":""},on:{"click":function($event){return _vm.doViewPhoto(item.Image)}}})]:_vm._e(),(_vm.doUpload !== undefined)?_c('div',{staticClass:"mx-2"},[(!item['Confirmed'] && (!item['Order'] || item['BankTransfer']))?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"blue","dark":!_vm.uploadState,"x-small":"","disabled":_vm.uploadState,"loading":_vm.uploadState},on:{"click":function($event){return _vm.doToggleUpload(item)}}},[_vm._v(" Tải lên hóa đơn ")]):_vm._e()],1):_vm._e(),_c('br')]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"dark":""},on:{"click":function($event){return _vm.doClose()}}},[_vm._v(" Đóng ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"900px"},model:{value:(_vm.boxPhoto),callback:function ($$v) {_vm.boxPhoto=$$v},expression:"boxPhoto"}},[_c('v-card',[_c('v-toolbar',{staticClass:"white--text",attrs:{"dark":"","color":"primary","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"body-2"},[_vm._v("Hình Ảnh")])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.boxPhoto = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-img',{attrs:{"src":((_vm.Global.staticURL) + "/" + _vm.file),"lazy-src":((_vm.Global.staticURL) + "/" + _vm.file),"aspect-ratio":"1","contain":"","max-width":"100%","max-height":"100%","lazy-load":""}})],1)],1)],1),_c('input',{ref:"uploader",staticClass:"hidden",staticStyle:{"display":"none"},attrs:{"type":"file","id":"uploader","accept":"image/*"},on:{"change":_vm.toggleFile}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }