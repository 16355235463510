import { Request } from '@/plugins/helper/fetch';
const req = new Request('shipment');
const qrSearch = (query) => {
	return req.get(`list?Type=${query}`).then(response =>response.json());
}
const qrLastPackage = (id) => {
	return req.get(`last-package/${id}`).then(response =>response.json());
}
export default {
	qrSearch,
	qrLastPackage,
}