<template>
	<div>
		<v-dialog
			v-model="closeState"
			persistent
		>
			<v-card flat>
				<v-card-title>
					LỊCH SỬ GIAO DỊCH: {{account}}
					<v-spacer />
					<v-btn
						class="mx-2"
						dark
						@click="doClose()"
					>
						Đóng
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-text-field
						v-model="search"
						class="mx-2"
						label="Tìm kiếm"
					/>
				</v-card-text>
				<v-card-text>
					<v-responsive>
            <Loader :status="loadingState" />
            <v-data-table
							:options.sync="options"
							:server-items-length="total"
							:loading="loadingState"
							:headers="headers"
							:items="data"
							:search="search"
							loading-text="Đang tải dữ liệu..."
							:footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
							:items-per-page="20"
						>
              <template v-slot:[`item.PaymentType`]="{ item }">
                <div class="text-body2 green--text text-center" v-if="item.Balance && !item.BankTransfer">
                  Ví TO
                </div>
                <div class="text-body2 blue--text text-center" v-if="item.BankTransfer">
                  Chuyển khoản
                </div>
              </template>
							<template v-slot:[`item.Confirmed`]="{ item }">
								<template v-if="item['Confirmed']">
									<v-btn
										color="red"
										dark
										small
									>
										Đã đối soát
									</v-btn>
								</template>
								<template v-else-if="!item['Confirmed'] && doConfirm !== undefined && (item.Balance || item['BankTransfer'])">
									<v-btn
										color="blue"
										:dark="!confirmState"
										small
										v-if="(item.Balance && item.Balance.Plus) || item['BankTransfer']"
										@click="doConfirm(item._id)"
										:disabled="confirmState"
										:loading="confirmState"
									>
										Xác nhận
									</v-btn>
								</template>
                <template v-if="!item['Confirmed'] && doConfirm === undefined && item['BankTransfer']">
                  <v-btn
                      color="blue"
                      dark
                      small
                  >
                    Chưa đối soát
                  </v-btn>
                </template>
							</template>
							<template v-slot:[`item.Order`]="{ item }">
								<template v-if="item.Order">
									{{item.Order.Order}} / {{item.Order.Product}}
								</template>
								<template v-else>
									<span class="text-body2 red--text">Trống</span>
								</template>
							</template>
              <template v-slot:[`item.Amount`]="{ item }">
                <div class="text-body2 green--text text-center" v-if="item.Amount">
                  {{ DoiTien(item.Amount) }}
                </div>
              </template>
							<template v-slot:[`item.Balance`]="{ item }">
                <template v-if="item.Balance">
                  <div class="text-body2 green--text text-center" v-if="item.Balance['Plus']">+{{ DoiTien(item.Balance.Plus) }}</div>
                  <div class="text-body2 red--text text-center" v-if="item.Balance['Minus']">-{{ DoiTien(item.Balance.Minus) }}</div>
                </template>
							</template>
							<template v-slot:[`item.Balance.Before`]="{ item }">
                <template v-if="item.Balance">
                  <div class="text-body2 blue--text text-center">{{ DoiTien(item.Balance.Before) }}</div>
                </template>
							</template>
							<template v-slot:[`item.Balance.After`]="{ item }">
                <template v-if="item.Balance">
                  <div class="text-body2 green--text text-center" v-if="item.Balance['Plus']">{{ DoiTien(item.Balance.After) }}</div>
                  <div class="text-body2 red--text text-center" v-if="item.Balance['Minus']">{{ DoiTien(item.Balance.After) }}</div>
                </template>
							</template>
							<template v-slot:[`item.Notes`]="{ item }">
								<v-textarea
									:value="item.Notes"
									label="Ghi chú (Khách hàng)"
									readonly
									rows="1"
								/>
							</template>
							<template v-slot:[`item.Logs`]="{ item }">
								<v-textarea
									:value="item.Logs"
									label="Ghi chú (Nội bộ)"
									readonly
									rows="1"
								/>
							</template>
							<template v-slot:[`item.createdAt`]="{ item }">
								{{TGian(item.createdAt)}}
							</template>
							<template v-slot:[`item.Image`]="{ item }">
								<template v-if="item.Image">
									<v-img
										:src="`${Global.staticURL}/${item.Image}`"
										:lazy-src="`${Global.staticURL}/${item.Image}`"
										aspect-ratio="1"
										contain
										max-width="250px"
										max-height="250px"
										lazy-load
										@click="doViewPhoto(item.Image)"
									/>
								</template>
								<div class="mx-2" v-if="doUpload !== undefined">
									<v-btn
										class="mx-2"
										color="blue"
										:dark="!uploadState"
										x-small
										@click="doToggleUpload(item)"
										:disabled="uploadState"
										:loading="uploadState"
										v-if="!item['Confirmed'] && (!item['Order'] || item['BankTransfer'])"
									>
										Tải lên hóa đơn
									</v-btn>
								</div>
								<br />
							</template>
						</v-data-table>
          </v-responsive>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn
						class="mx-2"
						dark
						@click="doClose()"
					>
						Đóng
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog
			max-width="900px"
			v-model="boxPhoto"
		>
			<v-card>
				<v-toolbar
					dark
					color="primary"
					class="white--text"
					dense
				>
					<v-toolbar-title>
						<span class="body-2">Hình Ảnh</span>
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn
						icon
						dark
						@click="boxPhoto = false"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<v-img
						:src="`${Global.staticURL}/${file}`"
						:lazy-src="`${Global.staticURL}/${file}`"
						aspect-ratio="1"
						contain
						max-width="100%"
						max-height="100%"
						lazy-load
					/>
				</v-card-text>
			</v-card>
		</v-dialog>
		<input @change="toggleFile" type="file" ref="uploader" id="uploader" class="hidden" style="display:none" accept="image/*">
	</div>
</template>

<script>
import {DoiTien} from '@/plugins/helper/string';
import Loading from '@/components/base/Loading';
import Loader from '@/components/Loader.vue';
import moment from "moment-timezone";
import Global from '@/global';
import { Status } from '@/plugins/helper/dstatus';
export default {
	name: "transaction",
	components: {
		Loading,
    Loader,
	},
	data(){
		return {
			uploadID: null,
			notice: new Status(this.$swal),
			boxPhoto: false,
			file: null,
			options: {},
			search: null,
			headers: [
				{ text: 'Mã GD', align: 'center', sortable: false,value: 'Code',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Đơn hàng', align: 'center', sortable: false,value: 'Order',width:"auto",class:"grey lighten-2 black--text" },
        { text: 'Hình thức TT', align: 'center', sortable: false,value: 'PaymentType',width:"auto",class:"grey lighten-2 black--text" },
        { text: 'Số tiền (CK)', align: 'center', sortable: false,value: 'Amount',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Số dư (Thay đổi)', align: 'center', sortable: false,value: 'Balance',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Số dư (Trước)', align: 'center', sortable: false,value: 'Balance.Before',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Số dư (Sau)', align: 'center', sortable: false,value: 'Balance.After',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Đối soát', align: 'center', sortable: false,value: 'Confirmed',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Ghi chú (KH)', align: 'center', sortable: false,value: 'Notes',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Ghi chú (NB)', align: 'center', sortable: false,value: 'Logs',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Thời gian', align: 'center', sortable: true,value: 'createdAt',width:"auto",class:"grey lighten-2 black--text" },
				{ text: 'Hình Ảnh', align: 'center', sortable: true,value: 'Image',width:"auto",class:"grey lighten-2 black--text" },
			]
		}
	},
	props: ["account", "data", "total", "filter", "closeState", "loadingState", "uploadState", "confirmState", "doUpload", "doConfirm"],
	computed:{
		Global: () => Global,
	},
	watch:{
		options:{
			handler(){
				this.$emit('update:filter', {
					page: this.options.page,
					limit: this.options.itemsPerPage,
				});
			},
			deep: true
		}
	},
	methods:{
		DoiTien,
		doViewPhoto(Image){
			this.file = Image;
			this.boxPhoto = true;
		},
		toggleFile(e){
			this.files = e.target.files;
			if(e.target.files.length > 0){
				let data = new FormData();
				const file = e.target.files[0];
				data.append('file', file);
				this.doUpload(this.uploadID, data);
			}else{
				this.notice.error('Bạn chưa chọn file');
			}
			this.$refs.uploader.value = '';
		},
		doToggleUpload(item){
			this.uploadID = item._id;
			this.$refs.uploader.click();
		},
		doClose(){
			this.$emit('update:closeState', false);
		},
		TGian(time){
			const local = moment.tz.guess();
			return moment(time).tz(local).format("DD-MM-YYYY HH:mm")
		}
	}
}
</script>

<style scoped>

</style>